﻿import AccordionHelper from "./Helper/AccordionHelper";

document.addEventListener("DOMContentLoaded", function(event) {
    bindAccordionEvents();
    bindRefreshEvent();
    bindTabListeners();
    checkIfDayTargetSet();
});

function bindAccordionEvents() {
    keyAndRiskAccordionEvent();
    summaryAndActionAccordionEvent();
}

function keyAndRiskAccordionEvent() {
    const section = document.querySelector(".key-risk-information");
    // to instantly hide the section, otherwise weird close effect after load
    if (section) {
        section.style.display = "none";
    }

    const accordionHelper = new AccordionHelper();
    accordionHelper.Close(".key-risk-information-section");
    document.querySelector(".key-risk-information-what-does-this-key-mean").addEventListener("click", function () {
        accordionHelper.ToggleState(".key-risk-information-section");
    });
}

function summaryAndActionAccordionEvent() {
    const section = document.querySelector(".action-information-mobile__content");
    // to instantly hide the section, otherwise weird close effect after load
    if (section) {
        section.style.display = "none";
    }

    const accordionHelper = new AccordionHelper();
    accordionHelper.Close(".action-information-mobile");
    document.querySelector(".action-information-mobile__summary").addEventListener("click", function () {
        accordionHelper.ToggleState(".action-information-mobile");
    });
}

function bindRefreshEvent() {
    document.querySelector("#refresh-forecast").addEventListener("click", function () {
        refresh(false);
    });
    document.querySelector("#refresh-forecast-mobile").addEventListener("click", function () {
        refresh(true);
    });
}

function bindTabListeners() {
    // code to be used for mobile daily forecast tab menu and user daily position view
    const tabHolder = document.querySelector(".forecast-tab-menu");
    if (!tabHolder) { 
        return;
    }
    
    const tabItems = tabHolder.querySelectorAll(".forecast-tab > a");
    if (!tabItems || tabItems.length === 0) {
        return;
    }

    tabItems.forEach((tab) => {
        tab.addEventListener('click', (e) => {
            e.target.parentElement.scrollIntoView(false);
            e.preventDefault();
            clearActiveTabs();
            updateDayPosition(tab.id);
            setActiveTab(tab.id);
        });
        
        if (tab.parentElement.classList.contains('active')) {
            document.querySelector(`#day_${tab.id}`).classList.add('active');
        }
    });
}

function clearActiveTabs() {
    const activeTabs = document.querySelectorAll(".forecast-tab.active");
    if (activeTabs) {
        activeTabs.forEach(function(tab) {
            tab.classList.remove("active");
            document.querySelector(`#day_${tab.querySelector('a').id}`).classList.remove("active");
        });
    }
}

function setActiveTab(dayNumber) {
    const tabs = document.querySelectorAll(".forecast-tab-menu .forecast-tab > a");
    if (tabs.length > 0) {
        const tab = tabs[dayNumber - 1];
        tab.parentElement.classList.add("active");
        document.querySelector(`#day_${tab.id}`).classList.add("active");
    }
}

function updateDayPosition(dayId) {
    const dayPosition = document.querySelector("#dayPosition");
    if (dayPosition) {
        dayPosition.value = dayId.replace("day_", "");
    }
}

function recheckDailyForecastPosition() {
    // Get a list of all of the forecast days
    const container = document.querySelector(".forecast-daily-container");
    const days = container.querySelectorAll(".daily-forecast");

    let activeDailyForecast = null;
    const halfScreenWidth = Math.max(window.innerWidth, 320) / 2;

    clearActiveTabs();
    for (let i = 0; i < days.length; ++i) {
        const rectangle = days[i].getBoundingClientRect();

        // Check if each forecast is the one taking up the majority of the page
        if (rectangle.left >= halfScreenWidth || rectangle.right <= halfScreenWidth) {
            continue;
        }

        updateDayPosition(days[i].id);
        setActiveTab(i + 1);
        activeDailyForecast = days[i];
        break;
    }

    // Return the X coordinate of the left hand side of the forecast for tracking
    if (!activeDailyForecast) {
        return 0;
    }
    return activeDailyForecast.getBoundingClientRect().left;
}

function checkIfDayTargetSet() {
    const dayPosition = document.querySelector("#dayPosition");
    if (dayPosition && dayPosition.value > "0" && dayPosition.value < "6") {
        document.querySelector(".forecast-tab.active > a").click();
    }
}

function refresh(isMobile) {
    const dayTarget = document.querySelector("#dayPosition");
    if (!dayTarget || !isMobile) {
        window.location.reload();
    } else {
        window.location = `/${dayTarget.value}`;
    }
}