﻿export default class AccordionHelper {
    static classAccordionCloseModifier() { return "accordion--closed"; }
    static classAccordionContent() { return ".accordion__content"; }

    ToggleState(accordion, force = null) {
        var $accordion = $(accordion);
        $accordion.toggleClass(AccordionHelper.classAccordionCloseModifier());

        var $title = $accordion.find(".accordion__title-bar");
        var $content = $accordion.find(AccordionHelper.classAccordionContent());

        var contentId = $content[0].id;

        if (!contentId) {
            // an id is required for accessibility aria tags.
            contentId = "random-id-" + Math.random().toString(36).slice(-5);
            $content[0].id = contentId;
        }

        var titleId = $title[0].id;

        if (!titleId) {
            // an id is required for accessibility aria tags.
            titleId = "random-id-" + Math.random().toString(36).slice(-5);
            $title[0].id = titleId;
        }

        var activeElement = document.activeElement;

        if (force === false || (force !== true && $accordion.hasClass(AccordionHelper.classAccordionCloseModifier()))) {
            $content
                .attr("aria-labelledby", titleId)
                .slideUp(250);
            $title
                .attr("aria-expanded", false)
                .removeAttr("aria-activedescendant");
            
            if ($(activeElement).closest($content).length !== 0) {
                $title.focus();
            }
            return false;
        } else {
            $content
                .attr("aria-labelledby", titleId)
                .slideDown(250, function () {
                    $title
                        .attr("aria-activedescendant", contentId);
                });

            $title
                .attr("aria-expanded", true);
            return true;
        }
    }
    
    Close(accordion) {
        var $accordion = $(accordion);
        var $content = $(accordion).find(AccordionHelper.classAccordionContent());
        $accordion.addClass(AccordionHelper.classAccordionCloseModifier());
        $content.slideUp(250);
    }
}